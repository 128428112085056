/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
/* eslint-enable no-unused-vars */

const isIE11orBelow = () => false || !!document.documentMode;

const FCA = (
  <div className="col-sm-12 home_section1_min-height fca_notes">
    <p><b>Estimated reading time: 2 min</b></p>
    <p>Due to the potential for losses, the Financial Conduct Authority (FCA) considers this investment to be high risk.</p>
    <p><b>What are the key risks?</b></p>
    <ol className="fca_ribbon">
      <li>You could lose the money you invest
        <ul className="fca_ul">
          <li>
            Many peer-to-peer (P2P) loans are made to borrowers who can’t borrow money from traditional lenders such as banks. These borrowers have a higher risk of not paying you back.
          </li>
          <li>
            Advertised rates of return aren’t guaranteed. If a borrower doesn’t pay you back as agreed, you could earn less money than expected. A higher advertised rate of return means a higher risk of losing your money.
          </li>
          <li>
            These investments can be held in an Innovative Finance ISA (IFISA). An IFISA does not reduce the risk of the investment or protect you from losses, so you can still lose all your money. It only means that any potential gains from your investment will be tax free.
          </li>
        </ul>
      </li>
      <br />
      <li>You are unlikely to get your money back quickly
        <ul className="fca_ul">
          <li>Some P2P loans last for several years. You should be prepared to wait for your money to be returned even if the borrower repays on time.</li>
          <li>Some platforms may give you the opportunity to sell your investment early through a ‘secondary market’, but there is no guarantee you will be able to find someone willing to buy.</li>
          <li>Even if your agreement is advertised as affording early access to your money, you will only get your money early if someone else wants to buy your loan(s). If no one wants to buy, it could take longer to get your money back.</li>
        </ul>
      </li>
      <br />
      <li>Don’t put all your eggs in one basket
        <ul className="fca_ul">
          <li>Putting all your money into a single business or type of investment for example, is risky. Spreading your money across different investments makes you less dependent on any one to do well.</li>
          <li>A good rule of thumb is not to invest more than 10% of your money in <a className="underline-link-in-text" href="https://www.fca.org.uk/investsmart/5-questions-ask-you-invest">high-risk investments</a>. </li>
        </ul>
      </li>
      <br />
      <li>
        The P2P platform could fail
        <ul className="fca_ul">
          <li>If the platform fails, it may be impossible for you to collect money on your loan. It could take years to get your money back, or you may not get it back at all. Even if the platform has plans in place to prevent this, they may not work in a disorderly failure.</li>
        </ul>
      </li>
      <br />
      <li>
        You are unlikely to be protected if something goes wrong
        <ul className="fca_ul">
          <li>
            The Financial Services Compensation Scheme (FSCS), in relation to claims against failed regulated firms, does not cover investments in P2P loans. You may be able to claim if you received regulated advice to invest in P2P, and the adviser has since failed. Try the FSCS investment protection checker <a className="underline-link-in-text" href="https://www.fscs.org.uk/check/investment-protection-checker/">here</a>
          </li>
          <li>
            Protection from the Financial Ombudsman Service (FOS) does not cover poor investment performance. If you have a complaint against an FCA-regulated platform, FOS may be able to consider it. Learn more about FOS protection <a className="underline-link-in-text" href="https://www.financial-ombudsman.org.uk/consumers">here</a>
          </li>
        </ul>
      </li>
    </ol>
    <p><b>If you are interested in learning more about how to protect yourself, visit the FCA’s website <a className="underline-link-in-text" href="https://www.fca.org.uk/investsmart">here</a></b></p>
    <p><b>For further information about peer-to-peer lending (loan-based crowdfunding), visit the FCA’s website <a className="underline-link-in-text" href="https://www.fca.org.uk/consumers/crowdfunding">here</a></b></p>
  </div>
);

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  HeaderBody = ({ page, button, isSafariBased, isInvestor }) => (
    <div>
      {isInvestor && <div className="header-alert-investor">
        <div className="header-ribbon-banner">
          <div className="container row-flex" style={{ height: "8%" }}>
            <div className="header-info-6 header-info-mob-5">
              <span className={isSafariBased ? "header-info-text fca_header safari-based" : "header-info-text fca_header"}>
                Don't invest unless you're prepared to lose money. This is a high-risk investment. You may not be able to access your money easily and are unlikely to be protected if something goes wrong.{" "}
                <a href="#!" className={isSafariBased ? "header-info-text header-info-text-right ribbon fca_header safari-based" : "header-info-text header-info-text-right ribbon fca_header"} onClick={this.handleClickOpen}>
                  Take 2 minutes to learn more.
                </a>
              </span>
            </div>
          </div>
        </div>
        <Dialog style={{ marginTop: "150px" }} open={this.state.open} onClose={this.handleClose}>
          <DialogContent>
            <DialogContentText>{FCA}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} style={{ fontSize: "14px" }} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{ backgroundColor: "black", height: "3px" }}></div>
      </div>}

      {!isInvestor && <div className="header-alert-investor">
        <div className="header-ribbon-banner">
          <div className="container row-flex" style={{ height: "8%" }}>
            <div className="header-info-6 header-info-mob-5">
              <span className={isSafariBased ? "header-info-text fca_header safari-based" : "header-info-text fca_header"}>
                Lendwise offers loans with flexible repayment terms. All loans are subject to status and affordability checks. Representative APR applies. T&Cs apply. Lendwise is authorised and regulated by the Financial Conduct Authority (FRN 782496).{" "}
              </span>
            </div>
          </div>
        </div>
        <Dialog style={{ marginTop: "150px" }} open={this.state.open} onClose={this.handleClose}>
          <DialogContent>
            <DialogContentText>{FCA}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} style={{ fontSize: "14px" }} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{ backgroundColor: "black", height: "3px" }}></div>
      </div>}

      <div className="container ">
        <div className="logo-wrap">
          <img className="logo logo--color" src="/images/logo-lendwise.svg" alt="Lendwise" />
          <img className="logo logo--white" src="/images/logo-lendwise--white.svg" alt="Lendwise" />
        </div>

        <ul className="header__nav">
          <li className="header__item">
            <a href="#!" data-scroll="product">
              {page.menuTitle}
            </a>
          </li>
          <li className="header__item">
            <a href="#!" data-scroll="example">
              Example
            </a>
          </li>
          <li className="header__item">
            <a href="#!" data-scroll="more-info">
              More Info
            </a>
          </li>
          <li className="header__item">
            <a href="https://www.lendwise.com/help">Help</a>
          </li>
          <li>
            <a href={button.link} className="btn btn--arrow btn--alt">
              <span>{button.text}</span>
              <svg role="img" className="icon icon-arrow-right">
                <use xlinkHref="/icons/icons.svg#icon-arrow-right" />
              </svg>
            </a>
          </li>
        </ul>
        <button type="button" className="menu-trigger" aria-label="menu">
          <svg role="img" className="icon icon-menu">
            <use xlinkHref="/icons/icons.svg#icon-menu" />
          </svg>
        </button>
      </div>
    </div>
  );

  render() {
    const { page, button } = this.props;
    let isSafariBased = false;
    let isInvestor = false;
    if (navigator.vendor.startsWith("Apple")) {
      isSafariBased = true;
    }
    if (page.paths[0].path === "start-investing") {
      isInvestor = true;
    }

    const header = <this.HeaderBody page={page} button={button} isSafariBased={isSafariBased} isInvestor={isInvestor} />;
    return isIE11orBelow() ? <div className="header">{header}</div> : <header className="header">{header}</header>;
  };
}
export default connect()(Header);
